import {
  AspectRatio,
  Box,
  Button,
  Center,
  Container,
  Heading,
  Image,
  Link,
  Stack,
} from '@chakra-ui/react'
import { t, Trans } from '@lingui/macro'

import { GoogleOneTapButton } from 'modules/marketing_pages/components/MarketingUI/components/GoogleOneTapButton'
import { MARKETING_SITE_LINKS } from 'modules/marketing_pages/components/MarketingUI/constants'
import doorImg from 'publicImages/door.png'
import footerTopImg from 'publicImages/footer-top.png'
import gammaAnimationImg from 'publicImages/gamma-animation.gif'
import leftSideImg from 'publicImages/Left-Side.png'
import rightSideImg from 'publicImages/Right-Side.png'

import { ORDERED_HOMEPAGE_SECTIONS } from './constants'
import { HomeContainer } from './HomeContainer'
import { HomeSection } from './HomeSection'
export const Home = () => {
  const videoUrl =
    'https://www.youtube-nocookie.com/embed/vtMIUtE9doQ?loop=1&modestbranding=1&autoplay=1&mute=1&cc_load_policy=1&playlist=vtMIUtE9doQ'

  return (
    <Stack
      w="100%"
      spacing={{ base: 24, lg: 0 }}
      position="relative"
      zIndex={1}
      bg="linear-gradient(#faf2e9, rgba(250, 242, 233, 0))"
    >
      <GoogleOneTapButton />
      <Stack spacing={24} w="100%" zIndex={1} pb={24}>
        <Box w="100%" position="relative" pt={{ base: 24, md: 40 }}>
          <HomeContainer pos="relative" zIndex="1">
            <Stack
              spacing={{ base: 4, lg: 8 }}
              minH={{ base: undefined, lg: '55vh' }}
              justifyContent="center"
              alignItems="center"
              w="100%"
            >
              <Heading
                size={{ base: '2xl', lg: '6xl' }}
                maxW={{ base: '100%', md: '60%', lg: '50%' }}
                variant="fancy"
                textAlign="center"
                lineHeight="1.07 !important"
                // @ts-ignore
                textWrap="pretty"
              >
                <Trans>A new medium for presenting ideas.</Trans>
              </Heading>
              <Heading
                textAlign="center"
                color="blackAlpha.800"
                mt={{ base: -1, lg: -3 }}
                size={{ base: 'xl', lg: '5xl' }}
              >
                <Trans>Powered by AI.</Trans>
              </Heading>
              <Heading
                size={{ base: 'sm', lg: 'md' }}
                maxW={`min(500px, 100%)`}
                textAlign="center"
                variant="sansSerif"
                color="blackAlpha.700"
                lineHeight={'1.65 !important'}
                // @ts-ignore
                textWrap="pretty"
              >
                <Trans>Beautiful presentations, documents, and websites.</Trans>{' '}
                <Trans>No design or coding skills required.</Trans>
              </Heading>
              <Center>
                <Stack
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Button
                    size="xl"
                    variant="fancy"
                    as={Link}
                    href={MARKETING_SITE_LINKS.signup}
                    w="fit-content"
                  >
                    <Trans>Sign up for free</Trans>
                  </Button>
                </Stack>
              </Center>
            </Stack>
          </HomeContainer>
          <Box
            w="100%"
            position="absolute"
            zIndex="0"
            opacity={{ base: 0, sm: '.15', xl: '.95' }}
            justifyContent="space-between"
            display="flex"
            top="0%"
            bottom="0%"
            pointerEvents="none"
            left="0%"
            right="0%"
            h={{ base: undefined, xl: '600px', '2xl': '700px' }}
          >
            <Box w="100%" zIndex={0} pt={{ base: 10, xl: 40 }}>
              <Box
                w="100%"
                position="absolute"
                h="100%"
                bgImage={`url(${leftSideImg.src})`}
                bgSize="contain"
                bgRepeat="no-repeat"
                bgPosition="left"
                display={['none', 'none', 'block']}
                pointerEvents="none"
              />
              <Box
                w="100%"
                position="absolute"
                h="100%"
                bgImage={`url(${rightSideImg.src})`}
                bgSize="contain"
                bgRepeat="no-repeat"
                bgPosition="right"
                display={['none', 'none', 'block']}
                pointerEvents="none"
              />
            </Box>
            <Box
              position="absolute"
              zIndex={1}
              overflow="hidden"
              data-bg-circles
              h="100vh"
              w="100%"
            >
              <Box
                opacity="0.24"
                filter="blur(220px)"
                webkit-text-fill-color="inherit"
                backgroundColor="#dcc0ff"
                background-clip="border-box"
                borderRadius="100%"
                width="50.34vw"
                height="50.34vw"
                position="absolute"
                top="0%"
                bottom="auto"
                left="-7.9vw"
              />

              <Box
                opacity="0.2"
                filter="blur(220px)"
                backgroundColor="#f99b52"
                borderRadius="100%"
                width="50.34vw"
                height="50.34vw"
                position="absolute"
                top="0%"
                bottom="auto"
                right="-7.9vw"
              />

              <Box
                opacity="0.14"
                filter="blur(220px)"
                backgroundColor="#f2ef3f"
                borderRadius="100%"
                width="56.45vw"
                height="56.45vw"
                margin-top="-22%"
                position="absolute"
                top="0%"
                bottom="auto"
                left="21.775vw"
                right="21.775vw"
              />
              <Box
                filter="blur(80px)"
                backgroundImage="radial-gradient(circle, #fff, rgba(255, 255, 255, .2))"
                borderRadius="100%"
                width="66vw"
                height="66vw"
                marginTop="-85px"
                position="absolute"
                top="0%"
                bottom="auto"
                left="17vw"
                right="27vw"
              />
            </Box>
          </Box>
        </Box>
        <Container maxW="1280px" px={{ base: 4, md: 8 }}>
          <AspectRatio ratio={16 / 9}>
            <Box w="100%" borderRadius="xl" boxShadow="2xl">
              <iframe
                width="100%"
                height="100%"
                src={videoUrl}
                title={t`Introducing Gamma: Presentations, Websites, and More, powered by AI`}
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerPolicy="strict-origin-when-cross-origin"
                allowFullScreen
              />
            </Box>
          </AspectRatio>
        </Container>
        {ORDERED_HOMEPAGE_SECTIONS.map((section, ind) => (
          <HomeSection key={ind} {...section} />
        ))}
      </Stack>
      <Stack spacing={0} zIndex={1}>
        <Center display={{ base: 'none', lg: 'flex' }} pb={4}>
          <Image
            src={gammaAnimationImg.src}
            transform="translate3d(0px, -19.4672px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)"
            willChange="transform"
            borderRadius="full"
            border="4px solid #ffeef3"
            overflow="hidden"
            w="200px"
          />
        </Center>
        <Box
          display={{ base: 'none', lg: 'block' }}
          w="100%"
          h="400px"
          bgImage={`url(${footerTopImg.src})`}
          bgPosition="bottom"
          bgSize="contain"
          bgRepeat="no-repeat"
        />
        <Box bg="#1d0042" w="100%" pt={{ base: 16, md: 32 }}>
          <HomeContainer>
            <Stack
              w="100%"
              alignItems="center"
              justifyContent="center"
              spacing={8}
            >
              <Heading
                size="3xl"
                color="whiteAlpha.500"
                variant="fancyDark"
                textAlign="center"
              >
                <Trans>Open the door to a new universe</Trans>
              </Heading>
              <Heading
                size="md"
                textAlign="center"
                variant="sansSerif"
                lineHeight={1.65}
                color="whiteAlpha.700"
              >
                <Trans>
                  More visual than a doc. More collaborative than a slide deck.
                  More interactive than a video.
                </Trans>
              </Heading>

              <Button
                variant="fancy"
                size="xl"
                width="fit-content"
                as={Link}
                href={MARKETING_SITE_LINKS.signup}
              >
                <Trans>Try Gamma for free</Trans>
              </Button>
              <Image
                w="700px"
                maxW="80vw"
                src={doorImg.src}
                alt="Open the door to a new universe"
              />
            </Stack>
          </HomeContainer>
        </Box>
      </Stack>
    </Stack>
  )
}
