import { Container, ContainerProps } from '@chakra-ui/react'

import { HOME_CONTAINER_MAX_WIDTH } from './constants'

export const HomeContainer = ({ children, ...rest }: ContainerProps) => {
  return (
    <Container
      maxW={HOME_CONTAINER_MAX_WIDTH}
      px={{ base: 4, md: 8 }}
      {...rest}
    >
      {children}
    </Container>
  )
}
