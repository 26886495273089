import { GetStaticPaths, GetStaticProps } from 'next'

import { withGammaMarketingAppProviders } from 'appProviders/marketing'
import { withMarketingLayout } from 'layout'
import { withGoogleTagManager } from 'modules/googleTagManager'
import {
  getStaticPathsMarketing,
  getStaticPropsMarketing,
} from 'modules/i18n/hooks/useLinguiInitSSG'
import { Home } from 'sections/marketing/Home/Home'

export const MarketingHomePage = () => {
  return <Home key={'home-v2'} />
}

export const getStaticProps: GetStaticProps = getStaticPropsMarketing
export const getStaticPaths: GetStaticPaths = getStaticPathsMarketing

export default withGammaMarketingAppProviders(
  withGoogleTagManager(withMarketingLayout(MarketingHomePage, { fixed: true }))
)
