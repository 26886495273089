import Head from 'next/head'

import { config } from 'config'
import { useFeatureFlag } from 'modules/featureFlags'
import { useUserContext } from 'modules/user'
import { isRobot } from 'utils/deviceDetection'
import { useSSRMounted } from 'utils/hooks'
import { shouldUsePublishedVersion } from 'utils/publishing'

export const GoogleOneTapButton = () => {
  const googleOneTapEnabled = useFeatureFlag('googleOneTap')
  const { user, isUserLoading } = useUserContext()

  const ssrMounted = useSSRMounted()
  if (!googleOneTapEnabled || user || isUserLoading || !ssrMounted) {
    return null
  }
  return (
    <>
      <Head>
        {!isRobot() && !shouldUsePublishedVersion() && (
          <script src="https://accounts.google.com/gsi/client" async></script>
        )}
      </Head>
      <div
        id="g_id_onload"
        data-client_id={config.GOOGLE_DRIVE_IMPORT_CLIENT_ID}
        data-login_uri={`${config.API_HOST}/login?popup=false`}
      />
    </>
  )
}
